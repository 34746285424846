import React, { useState } from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue } from "./EventHandler"
const MaterialsAndCompositions = (props) => {
   let data = []
   data = props.data
 
   const getHeader = () => {
      var keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }
 
   const getRowsData = () => {
      var items = data
      let rootNodes = items.filter((node) => node.parentName === "")
      var keys = getKeys()
      return rootNodes.map((node, index) => {
         return <RenderRow node={node} keys={keys} i={index}/>
      })
   }
 
   const getKeys = function () {
      return Object.keys(data[0])
   }
 
   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }
 
   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            key.toLowerCase() === "n" &&
            props.id &&
            props.id.toLowerCase().trim() !== "no access"
         ) {
            let name = props.data[key]
            let link = props.data["link"]
 
            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>
                     {link ? (
                        <CustomLink id={props.id} link={link} name={name} />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         } else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
 
   const RenderRow = ({ node, keys, i, countMinus = 0, countPlus = 0, root = true }) => {
      const [childData, setChildData] = useState({
         expand: root ? true : false,
         countMinus: countMinus,
         countPlus: countPlus,
      })
     
      let childNodes = []
      childNodes = data.filter((child) => child.parentName === node["names"])
     
 
      const handleRootClick = () => {
         setChildData((data) => ({
            ...data,
            expand: !childData.expand,
            countMinus: 0,
         }))
      }
 
      const handleClick = () => {
         setChildData((data) => ({
            ...data,
            expand: !childData.expand,
         }))
      }
      var colKeys = []
      return (
         <>
            <tr key={i} style={{ borderBottom: "1px solid grey", borderCollapse: "collapse" }}>
               {keys.map((key, index) => {
                  if (
                     node[key] != null &&
                     typeof node[key] == "object" &&
                     key === "Name(N) Type(T)"
                  ) {
                     colKeys = getColumnKeys(node[key])
                     return (
                        <td
                           key={index}
                           onClick={root ? handleRootClick : handleClick}
                           style={{ display: "flex", width: "max-content" }}
                        >
                           <div>
                              {childNodes.length > 0 ? (
                                 childData.expand ? (
                                    <i
                                       style={{ marginLeft: childData.countMinus }}
                                       className="fa fa-minus designParamPlus"
                                       aria-hidden="true"
                                    ></i>
                                 ) : (
                                    <i
                                       style={{ marginLeft: childData.countPlus }}
                                       className="fa fa-plus designParamPlus"
                                       aria-hidden="true"
                                    ></i>
                                 )
                              ) : (
                                 <span
                                    style={{ marginLeft: childData.countMinus + 30 }}
                                 ></span>
                              )}
                           </div>
                           <div>
                              <RenderLinkColumn
                                 isbold={true}
                                 id={node["id"]}
                                 data={node[key]}
                                 keys={colKeys}
                              />
                           </div>
                        </td>
                     )
                  } else if (
                     key.toLowerCase() === "name" &&
                     node["id"] &&
                     node["id"].toLowerCase().trim() !== "no access"
                  ) {
                     var name = node[key]
                     return (
                        <td
                           style={{ display: "flex", width: "max-content" }}
                           key={index}
                           onClick={root ? handleRootClick : handleClick}
                        >
                           {childNodes.length > 0 ? (
                              childData.expand ? (
                                 <i
                                    style={{ marginLeft: childData.countMinus }}
                                    className="fa fa-minus designParamPlus"
                                    aria-hidden="true"
                                 ></i>
                              ) : (
                                 <i
                                    style={{ marginLeft: childData.countPlus }}
                                    className="fa fa-plus designParamPlus"
                                    aria-hidden="true"
                                 ></i>
                              )
                           ) : (
                              <span
                                 style={{ marginLeft: childData.countMinus + 22 }}
                              ></span>
                           )}
                           {node["link"] != null && node["link"] !== "" ? (
                              <CustomLink
                                 id={node["id"]}
                                 link={node["link"]}
                                 name={name}
                              />
                           ) : (
                              <span>{name}</span>
                           )}
                        </td>
                     )
                  }
                  if (node[key] != null && typeof node[key] == "object") {
                     colKeys = getColumnKeys(node[key])
                     return (
                        <td key={index}>
                           <RenderColumn isbold={true} data={node[key]} keys={colKeys} />
                        </td>
                     )
                  } else if (setNonDispalyValue(key)) {
                     return null
                  } else {
                     return <td key={index}>{node[key]}</td>
                  }
               })}
            </tr>
            {childData.expand &&
               childNodes.map((child, index) => (
                  <RenderRow
                     node={child}
                     keys={keys}
                     index={index}
                     countMinus={childData.countMinus + 15}
                     countPlus={childData.countPlus + 15}
                     root={false}
                  />
               ))}
         </>
      )
   }
 
   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead>
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default MaterialsAndCompositions